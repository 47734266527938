<div class="row portfolio-container">
    <div class="col-sm-12">
        <h3 class="sub-title">
            <svg class="bi bi-grid-3x3-gap-fill margin-bottom-5" width="0.8em" height="0.8em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
            </svg>
            Portfolio
        </h3>
        <hr>
    </div>
    <div class="col-sm-12">
        <div class="row" id="portfolio-wrapper">
            <div class="col-sm-12 col-lg-3 portfolio-item">
                <img src="../../assets/build-a-bot-logo.png" alt="build a robot logo">
                <h4>Build a Robot</h4>
                <p>Shopping like application, where a user can customize their robot.</p>
                <p>Vue.js, Bootstrap, HTML5 & SASS</p>
                <div>
                    <a target="_blank" class="btn btn-outline-primary margin-right-8" href="https://cassandrehenriquez-buildarobot.netlify.app">Live site</a>
                    <a target="_blank" class="btn btn-outline-primary" href="https://github.com/cassandreh1/build-a-bot">View code</a>    
                </div>
                </div>
            <div class="col-sm-12 col-lg-3 portfolio-item">
                <img id="mattr-logo" src="../../assets/mattr-logo.png" alt="mattr logo">
                <h4>Mattr</h4>
                <p>Developed a responsive proof of concept homepage for ad agency.</p>
                <p>JavaScript, PHP & CSS</p>
                <div>
                    <a target="_blank" class="btn btn-outline-primary margin-right-8" href="https://cassandreh1.github.io/Mode-Dev/">Live site</a>
                    <a target="_blank" class="btn btn-outline-primary" href="https://github.com/cassandreh1/Mode-Dev">View code</a>    
                </div>
                </div>
            <div class="col-sm-12 col-lg-3 portfolio-item">
                <img src="../../assets/tac-tic-toe-logo.png" alt="tic tac toe logo">
                <h4 style="margin-top: 5px;">Tic Tac Toe</h4>
                <p> Programmed a fun tic tac toe game following React's tutorial. </p>
                <p>React.js, HTML5, CSS</p>
                <div>
                    <a target="_blank" class="btn btn-outline-primary margin-right-8" href="https://cassandrehenriquez-tictactoe.netlify.app">Live site</a>
                    <a target="_blank" class="btn btn-outline-primary" href="https://github.com/cassandreh1/tic-tac-toe">View code</a>
                </div>
            </div>
            <!-- <div class="col-sm-12 col-lg-3 portfolio-item">
                <fa-icon [icon]="faHardHat" class="fa-3x" [ngStyle]="{ 'line-height': 0 }"></fa-icon>
                <p style="font-size: 12px; margin-bottom: 0px;">Work in progress...</p>
                <h4 style="margin-top: 5px;">To DO</h4>
                <p> Coming soon... </p>
                <p></p>
                <div>
                    <a class="btn btn-outline-primary margin-right-8" href="">Live site</a>
                    <a class="btn btn-outline-primary" href="">View code</a>
                </div>
            </div> -->
        </div>
    </div>
</div> 