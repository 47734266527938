<div class="row">
  <div class="col-sm-12" id="eduction-styling">
    <div id="resume-pdf">
      <h5 class="sub-title">
        <svg
          class="bi bi-book-half"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M12.786 1.072C11.188.752 9.084.71 7.646 2.146A.5.5 0 0 0 7.5 2.5v11a.5.5 0 0 0 .854.354c.843-.844 2.115-1.059 3.47-.92 1.344.14 2.66.617 3.452 1.013A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.276-.447L15.5 2.5l.224-.447-.002-.001-.004-.002-.013-.006-.047-.023a12.582 12.582 0 0 0-.799-.34 12.96 12.96 0 0 0-2.073-.609zM15 2.82v9.908c-.846-.343-1.944-.672-3.074-.788-1.143-.118-2.387-.023-3.426.56V2.718c1.063-.929 2.631-.956 4.09-.664A11.956 11.956 0 0 1 15 2.82z"
          />
          <path
            fill-rule="evenodd"
            d="M3.214 1.072C4.813.752 6.916.71 8.354 2.146A.5.5 0 0 1 8.5 2.5v11a.5.5 0 0 1-.854.354c-.843-.844-2.115-1.059-3.47-.92-1.344.14-2.66.617-3.452 1.013A.5.5 0 0 1 0 13.5v-11a.5.5 0 0 1 .276-.447L.5 2.5l-.224-.447.002-.001.004-.002.013-.006a5.017 5.017 0 0 1 .22-.103 12.958 12.958 0 0 1 2.7-.869z"
          />
        </svg>
        EDUCATION
      </h5>
      <a
        target="_blank"
        class="btn btn-danger"
        href="../../assets/Cassandre's Resume.pdf"
      >
        <fa-icon [icon]="faFilePdf"></fa-icon>
        Download Resume</a
      >
      <hr />
    </div>
    <div class="col-sm-12 education-content">
      <p>
        <img
          src="../../assets/owlhead-logo.png"
          alt="FAU owl head logo"
        /><strong class="blue-color">Florida Atlantic University, </strong> Boca
        Raton, FL
      </p>
      <p style="margin-left: 1.6em;">
        <strong class="blue-color"
          >Bachelor of Science in Computer Science,
        </strong>
        May 2016
      </p>
    </div>
  </div>
  <div class="col-sm-12">
    <h5 class="sub-title">
      <svg
        class="bi bi-briefcase-fill"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"
        />
        <path
          fill-rule="evenodd"
          d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5v1.384l-7.614 2.03a1.5 1.5 0 0 1-.772 0L0 5.884V4.5zm5-2A1.5 1.5 0 0 1 6.5 1h3A1.5 1.5 0 0 1 11 2.5V3h-1v-.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V3H5v-.5z"
        />
      </svg>
      EMPLOYMENT HISTORY
    </h5>
    <hr />
  </div>
  <div id="work-history">
    <div class="col-sm-12">
      <ul class="timeline">
        <h5>Software Engineer II at Bank of America</h5>
        <p class="date-range">Mar 2019 - Present</p>
        <li>
          Develop digital payments & services such as Zelle and integrate credit
          card and transfer applications on Bank of America’s website
        </li>
        <li>
          Design, build, and implement complex applications using languages such
          as JavaScript and Vue.js to enhance business processes
        </li>
        <li>
          Lead code reviews, requirement grooming ceremonies, and user training
          sessions attended by 20+ stakeholders
        </li>
        <li>
          Collaborate with Product Owners and UX teams to create Invision
          wireframes aligned with the bank technical standards
        </li>
        <li>
          Conduct technical interviews of potential candidates from a diverse
          background such as front-end developers
        </li>
        <li>
          Leverage components from the following tool stack: Vue.js, Axios.js
          for REST APIs calls, Handlebar for templating, Git & BitBucket
        </li>
      </ul>
    </div>
    <div class="col-sm-12">
      <ul class="timeline">
        <h5>Application Developer (TEKsystems contract) at Bank of America</h5>
        <p class="date-range">Nov 2018 - Mar 2019</p>
        <li>
          Built an application using JavaScript and jQuery to collect liability
          payments and provide users with the ability to add external accounts
        </li>
        <li>
          Used and maintained Bank of America’s custom JavaScript framework to
          develop balance transfer features between accounts
        </li>
        <li>
          Worked closely with Application Development Lead and Business Analysts
          to gather requirements and prioritize user stories
        </li>
        <li>
          Delivered financial solutions using the agile methodology as SDLC and
          JIRA as project management software
        </li>
        <li>
          Identified and managed innovative solutions to be leveraged by
          multiple scrum teams
        </li>
        <li>
          Hosted sessions with various teams to educate on latest development
          standards, resolve code conflicts, and triage maintenance tickets
        </li>
      </ul>
    </div>
    <div class="col-sm-12">
      <ul class="timeline">
        <h5>Senior Associate Web Developer at Synechron</h5>
        <p class="date-range">Feb 2017 - Oct 2018</p>
        <h6 class="sub-title padding-top-0">> Client: Third largest US bank</h6>
        <li>
          Created responsive applications’ features in Angular 5 based on
          requirements gathered during grooming sessions
        </li>
        <li>
          Implemented and followed the Model View Controller (MVC) design
          patterns to build clean reusable components
        </li>
        <li>
          Built classes and functions to capture data from diverse system using
          Angular’s HttpClient module
        </li>
        <li>Troubleshoot technical issues using Chrome’s developer tools</li>
        <li>
          Refactored application legacy source code to improve its performance
          and make it scalable
        </li>
        <li>
          Produced various deliverables such as the Technical Design Document to
          ensure a smooth transition to development teams
        </li>
        <li>
          Leveraged various SCM tools as GitHub to manage the source code of the
          application being developed
        </li>
        <h6 class="sub-title padding-top-0">> Internal Organization</h6>
        <li>
          Built help desk web application with CRUD (Create, Read, Update,
          Delete) functionalities for ticketing system using Angular.js.
          <a
            target="_blank"
            class="btn btn-outline-primary btn-sm"
            href="https://github.com/chenriq5/HelpDesk"
          >
            <svg
              class="bi bi-code-slash"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0zm-.999-3.124a.5.5 0 0 1 .33.625l-4 13a.5.5 0 0 1-.955-.294l4-13a.5.5 0 0 1 .625-.33z"
              />
            </svg>
            View Code
          </a>
        </li>
        <li>
          Worked on in house Synechron project, developing a web application
          using WordPress, HTML, CSS & jQuery
        </li>
      </ul>
    </div>
    <div class="col-sm-12 timeline">
      <ul class="timeline">
        <h5>Web Developer Intern at Synechron</h5>
        <p class="date-range">Jun 2016 - Aug 2016</p>
        <li>
          Implemented an application designed to track inventories on products
          such as books for a client using <br />Angular.js, Node.js, Express.js
          & MongoDB. Created CRUD functionalities to the front & back-end of the
          application.
          <a
            target="_blank"
            class="btn btn-outline-primary btn-sm"
            href="https://github.com/chenriq5/books_angular "
          >
            <svg
              class="bi bi-code-slash"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0zm-.999-3.124a.5.5 0 0 1 .33.625l-4 13a.5.5 0 0 1-.955-.294l4-13a.5.5 0 0 1 .625-.33z"
              />
            </svg>
            View code
          </a>
        </li>
      </ul>
    </div>
    <div class="col-sm-12">
      <!-- <h3>Academic Projects</h3> -->
    </div>
  </div>
</div>
<app-technical-skills
  [bgColor]="{
    'background-image': 'linear-gradient(to bottom, #e6f3f3, #b3d8d8)'
  }"
  [hrColor]="{ 'border-color': '#ff65bd' }"
  [titleColor]="{ color: '#ff65bd' }"
>
</app-technical-skills>
