<nav #stickyMenu class="navbar navbar-expand-lg fixed-top">
  <a class="navbar-brand" routerLink="/">
    <img src="../../assets/cass-logo.png" alt="Cassandre logo">
    Cassandre Henriquez
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <!-- <span class="navbar-toggler-icon"></span> -->
    <fa-icon [icon]="faBars"></fa-icon>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-md-auto">
      <li class="nav-item">
        <a class="nav-link" 
          routerLink="/" 
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <!-- id="navbarDropdown" 
          role="button" 
          data-toggle="dropdown" 
          aria-haspopup="true" 
          aria-expanded="false" > -->
            <fa-icon [icon]="faHome"></fa-icon>
            Home 
            <span class="sr-only">(current)</span>
        </a>
        <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#about-me-wrapper">About me</a>
          <a class="dropdown-item" href="#contact-me-wrapper">Contact me</a>
        </div> -->
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/resume" routerLinkActive="active" > 
          <fa-icon [icon]="faFile"></fa-icon>
          Resume</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/portfolio" routerLinkActive="active" > 
          <fa-icon [icon]="faGripHorizontal"></fa-icon>
          <!-- <svg class="bi bi-grid-3x3-gap-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
          </svg> -->
          Portfolio</a>
      </li>
    </ul>
  </div>
</nav>
<div class="hero-section">
  <div class="title-section">
    <span class="fast-flicker">C</span><span>ASSANDRE HENR</span><span class="flicker">I</span><span>QUEZ</span> 
    <h4>Software Engineer</h4>
    <svg class="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
      <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
    </svg>
  </div>
</div>