
<app-portfolio></app-portfolio>
<app-technical-skills 
  [bgColor]="{'background-image': 'linear-gradient(to bottom, #e6f3f3,  #e6f3f3)'}" 
  [hrColor]="{'border-color': '#ff65bd'}"
  [titleColor]="{'color': '#ff65bd'}">
</app-technical-skills>
<div class="row" id="checkResume">
  <div class="col-sm-12">
    <div class="alert alert-primary" role="alert">
      <a href="javascript:void(0)" routerLink="/resume"> 
        <svg class="bi bi-cursor-fill" width="1.3em" height="1.3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"/>
        </svg>
        CLICK HERE FOR MY RESUME!
      </a>
    </div>
  </div>
</div>
<div class="row no-gutters content-stye" id="about-me-wrapper">
  <div class="col-sm-12" id="about-me-title">
    <h3 class="title sub-title">
      <svg class="bi bi-person-square margin-bottom-5" width="0.8em" height="0.8em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
        <path fill-rule="evenodd" d="M2 15v-1c0-1 1-4 6-4s6 3 6 4v1H2zm6-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
      </svg>
      About me
    </h3>
    <hr>
  </div>
  <div class="col-sm-12 col-lg-7 content-container">
    <p> &nbsp; &nbsp;Bonjour le Monde! I’m Cassandre Henriquez, born in Brooklyn,
      and raised in Haiti. Following the devastating 2010 earthquake in Haiti,
      my family relocated to Wellington, Palm Beach, FL where I graduated high school.
      Upon graduation, I attended Florida Atlantic University, where I majored and graduated with a degree in Computer Science.
    </p>
      <p>&nbsp; &nbsp;Initially, I was unsure which path I wanted to follow as a CS major (vast IT universe).
       It wasn’t until I took a web development class that I found my niche. I enjoy bringing 
       a website/web application to life and working on creative websites that push my CSS skills.
    </p> 
     <p>
        &nbsp; &nbsp;I have spent the past 5 years as a developer in different 
        capacities in FSI (Financial Services and Insurance) industry. This portfolio 
        showcases my skills and upskill (forever a developer’s journey). 
        Check it out and reach out!
    </p>
    <p>Sincerely, </p>
    <p id="signature"> Cassandre Henriquez</p>
  </div>
  <div class="col-sm-12 col-lg-5" id="photo-container">
    <img src="../../assets/head-shot-sitting-5.jpg" alt="">
  </div>

</div>
<app-contact-me></app-contact-me>