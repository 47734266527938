<div>
  <header class="header-style">
    <app-header></app-header>
</header>
<div class="loading-spinner" *ngIf="loading">
  <fa-icon [icon]="faCircleNotch"></fa-icon>
</div> 
<div class="container-fluid" id="main-content"[@routeAnimation]="getAnimationData(routerOutlet)">
  <router-outlet #routerOutlet="outlet">
  </router-outlet>
</div>
<footer>
  <div class="row no-gutters">
    <div class="col-sm-12">
      <p>©2022 Cassandre Henriquez | All rights reserved</p>
    </div>
  </div>
  <fa-icon class="fa-2x" [icon]="faChevronUp" id="scroll-to-top" [hidden]="showButton" (click)="scrollToTop()" ></fa-icon>
</footer>
</div>