<div class="row justify-content-end" id="contact-me-wrapper">
    <div class="col-sm-12">
        <h3 class="sub-title">
            <svg class="bi bi-phone margin-bottom-5" width="0.8em" height="0.8em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M11 1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                <path fill-rule="evenodd" d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            </svg>
            Contact Me
        </h3>
        <hr>
    </div>
    <div class="col-sm-12 col-md-6 wall-art">
        <img id="contact-me-icon" src="../../assets/contact-icon.png" alt="email logo">
        <div id="contact-info">
            <p>
                Cassandre Henriquez
                <br>
                (561)584-3089
                <br>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-geo-alt" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                </svg>Coral Springs, FL
                <br>
                <a target="_blank" id="resume-pdf" href="../../assets/Cassandre's Resume.pdf">
                    <fa-icon [icon]="faFilePdf"></fa-icon>
                    Download Resume
                </a>
            </p>
            <div>
                <a target="_blank" class="contact-icon-styling white-color" href="https://github.com/cassandreh1"><i class="devicon-github-plain"></i></a>
                <a target="_blank" class="contact-icon-styling white-color"  href="https://www.linkedin.com/in/cassandre-henriquez-08bb4763/"><i class="devicon-linkedin-plain"></i></a>
                <a target="_blank" class="contact-icon-styling white-color"  href="mailto:cassandreh01@gmail.com">
                    <svg class="bi bi-envelope-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                    </svg>
                </a>
                
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <form #contactMe="ngForm" method="post" (ngSubmit)="onSubmit(contactMe)">
            <input type="hidden" name="form-name" value="contact" /> 
            <!-- checks for spams -->
            <p style='display: none;'>
                <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
            </p>
            <div *ngIf="showErrMsg" class="alert alert-danger alert-dismissible fade show" role="alert" >
                Something went wrong when sending your message. <br> You can contact me at <u>cassandreh01@gmail.com</u> or Try again later.
                <button type="button" class="close" (click)="closeAlert('fail')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

            <div *ngIf="showSuccessMsg" class="alert alert-success alert-dismissible fade show" role="alert" >
                Your message has been submitted!
                <button type="button" class="close" (click)="closeAlert('success')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            <div class="form-group">
                <input #firstName="ngModel" [(ngModel)]="model.firstName" name="firstName" type="text"
                    class="form-control" id="firstName" placeholder="First name" required>
                <div class="error-message" [hidden]="firstName.pristine || firstName.valid">
                    <svg class="bi bi-exclamation-triangle-fill" width="1em" height="1em" viewBox="0 0 16 16"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                    <p>First name is required</p>
                </div>
            </div>
            <div class="form-group">
                <input #lastName="ngModel" [(ngModel)]="model.lastName" name="lastName" type="text" class="form-control"
                    id="lastName" placeholder="Last name" required>
                <div class="error-message" [hidden]="lastName.pristine || lastName.valid">
                    <svg class="bi bi-exclamation-triangle-fill" width="1em" height="1em" viewBox="0 0 16 16"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                    <p>Last name is required</p>
                </div>
            </div>
            <div class="form-group">
                <input #email="ngModel" [(ngModel)]="model.email" name="email" type="text" class="form-control"
                    id="email" placeholder="Enter email" required>
                <div class="error-message" [hidden]="email.pristine || email.valid">
                    <svg class="bi bi-exclamation-triangle-fill" width="1em" height="1em" viewBox="0 0 16 16"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                    <p> Your email is required</p>
                </div>
            </div>
            <div class="form-group">
                <textarea #message="ngModel" [(ngModel)]="model.message" name="message" type="text" class="form-control"
                    id="message" placeholder="Enter your message" maxlength="160" required></textarea>
                    <div class="error-message" [hidden]="message.pristine || message.valid">
                        <svg class="bi bi-exclamation-triangle-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                        <p>Your message is required</p>
                    </div>
                    <!-- <p>{{message.value.length}}</p> -->
            </div>
            <button type="submit" class="btn btn-success btn-block">Submit</button>
        </form>
    </div>
  </div>